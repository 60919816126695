import React from "react"
import get from "lodash.get"

export interface BasicProps {
  title: string
  data: any[]
  style: "1" | "2" | "3"
}

export const Basic: React.FC<BasicProps> = ({ title, data, style }) => {
  const className =
    "uk-flex-center uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match margin-collapse"

  if (style === "1")
    return (
      <>
        <div>
          <h2 style={{ textAlign: "center" }}>{title}</h2>
        </div>
        <div>
          <div className="uk-card uk-card-default">
            <div className="uk-card-body">
              <div dangerouslySetInnerHTML={{ __html: data[0].title }} />
            </div>
          </div>
        </div>
      </>
    )
  else if (style === "2")
    return (
      <>
        <div>
          <h2 style={{ textAlign: "center" }}>{title}</h2>
        </div>
        <div>
          <div className={className} data-uk-grid>
            {data &&
              data.map(({ title, subtitle, img, text, link }, index) => (
                <div key={index.toString()}>
                  <div className="uk-card uk-card-default">
                    <div className="uk-cover-container">
                      <canvas width="500" height="500"></canvas>
                      <img
                        className="uk-width-1-1"
                        src={img || ""}
                        alt=""
                        data-uk-cover
                      />
                      <div className="uk-overlay uk-position-bottom-right">
                        <a href={link} target="_blank" title="linkedin">
                          <span data-uk-icon="icon: linkedin; ratio: 1"></span>
                        </a>
                      </div>
                    </div>

                    <div className="uk-card-body">
                      <h4 className="primary">{title}</h4>
                      <h5>{subtitle}</h5>
                      <p>
                        {text}
                        {/* {textStuffs && textStuffs.map(text => { text.text })} */}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </>
    )
  else {
    return (
      <>
        <div>
          <h2 style={{ textAlign: "center" }}>{title}</h2>
        </div>
        <div>
          <div className="uk-child-width-1-1" data-uk-grid>
            {data &&
              data.map(({ title, subtitle, img, link }, index) => (
                <div key={index.toString()}>
                  <a
                    href={link}
                    className="uk-display-block uk-card uk-card-default uk-card-body uk-card-hover"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="uk-flex-middle" data-uk-grid>
                      <img className="uk-width-1-4" src={`/${img}`} alt="" />
                      <h4 className="uk-width-expand">{title}</h4>
                    </div>
                  </a>
                </div>
              ))}
          </div>
        </div>
      </>
    )
  }
}

export const BasicBlock = {
  label: "Basic Block",
  name: "basic",
  key: "basicboi",
  fields: [
    {
      name: "title",
      label: "Title",
      component: "text",
    },
    {
      name: "style",
      label: "Style",
      component: "text",
      description:
        "Should be 1, 2, or 3. 1 is for `Story`, 2 is for `Team`, 3 is for `Press`.",
    },
    {
      name: "data",
      label: "Data",
      component: "group-list",
      fields: [
        {
          name: "title",
          label: "Title",
          component: "textarea",
          description: "Used for all 3 styles",
        },
        {
          name: "subtitle",
          label: "Subtitle",
          component: "text",
          description:
            "Only used for styles 2 and 3. Usage in style 3 should be a link to an image. If an actual image file is used (in the Image field) that will take priority over whatever is here.",
        },
        {
          name: "text",
          label: "Text",
          component: "text",
        },
        // {
        //   name: "textStuffs",
        //   label: "Text",
        //   component: "group-list",
        //   description: "Only used for 2",
        //   fields: [
        //     {
        //       name: "text",
        //       label: "Text",
        //       component: "text"
        //     }
        //   ]
        // },
        {
          name: "link",
          label: "URL",
          component: "text",
        },
        {
          name: "img",
          label: "Image",
          component: "image",
          parse: (filename) => filename,
          uploadDir: () => "/static/",
          previewSrc: (formValues, { input }) => {
            const path = input.name.replace("rawFrontmatter", "frontmatter")
            const gastbyImageNode = get(formValues, path)
            if (!gastbyImageNode) return ""
            //specific to gatsby-image
            return gastbyImageNode
          },
        },
      ],
      itemProps: (item: any) => ({ key: item.key, label: item.title }),
      defaultItem: {
        key: Math.random().toString(36).substr(2, 9),
        title: "item",
        subtitle: "",
        text: "",
        img: "",
      },
    },
  ],
}
