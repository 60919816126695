import React from "react"
import { Link } from "gatsby"
import get from "lodash.get"

export interface SolidBoiProps {
  title: string
  linkTitle: string
  linkUrl: string
  data: {
    img: string
    iconColor: string
    title: string
    description: string
    link?: string
  }[]
}

export const SolidBoi: React.FC<SolidBoiProps> = ({
  title,
  linkTitle,
  linkUrl,
  data,
}) => {
  return (
    <section className="uk-section uk-section-muted">
      <div
        className="uk-container uk-container-small uk-margin-large-bottom margin-collapse"
        data-uk-scrollspy="target: .uk-card, .uk-grid-small > *; cls: uk-animation-slide-bottom-medium; delay: 100"
      >
        <div className="uk-card uk-card-default uk-card-body">
          <div
            className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-flex-center"
            data-uk-grid
          >
            <div className="uk-width-1-1">
              <h2 className="uk-width-expand">{title}</h2>
              <p>
                <a href={linkUrl || "#"}>{linkTitle} →</a>
              </p>
            </div>
            {/* {data && data.map(item => (
              <div>
                <div className="uk-grid-small" data-uk-grid>
                  <div>
                    <div className={`uk-flex uk-flex-center uk-flex-middle icon-wrapper ${ item.iconColor }`}>
                      <img src={ item.img } alt="" data-uk-svg />
                    </div>
                  </div>
                  <div className="uk-width-expand">
                    <h4>
                      { item.title }
                    </h4>
                    {item.link ? <p><Link to={item.link}>{ item.description } →</Link></p> : <p> {item.description} </p> }
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </div>
      </div>
    </section>
  )
}

export const SolidBoiBlock = {
  label: "Solid Boi",
  name: "solidboi",
  key: "soliduboiu",
  fields: [
    {
      name: "title",
      label: "Title",
      component: "text",
    },
    {
      name: "linkTitle",
      label: "Link Text",
      component: "text",
    },
    {
      name: "linkUrl",
      label: "Link URL",
      component: "text",
    },
    {
      name: "data",
      label: "Items",
      component: "group-list",
      description: "Items for the thing.",
      fields: [
        {
          name: "title",
          label: "Title",
          component: "text",
        },
        {
          name: "description",
          label: "Description",
          component: "text",
        },
        {
          name: "iconColor",
          label: "Icon Bg Color",
          component: "text",
          description: "should be blue, orange, or green",
        },
        {
          name: "img",
          label: "Image",
          component: "image",
          parse: (filename) => filename,
          uploadDir: () => "/static/",
          previewSrc: (formValues, { input }) => {
            const path = input.name.replace("rawFrontmatter", "frontmatter")
            const gastbyImageNode = get(formValues, path)
            if (!gastbyImageNode) return ""
            //specific to gatsby-image
            return gastbyImageNode
          },
        },
      ],
      itemProps: (item: any) => ({ key: item.key, label: item.title }),
      defaultItem: {
        key: Math.random().toString(36).substr(2, 9),
        title: "Multi-item Item",
        description: "I'm an item!",
        iconColor: "blue",
        img: "bills.jpeg",
      },
    },
  ],
}
