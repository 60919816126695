import React from "react"

import Layout from "../components/layout/layout"
import { PhoneCarouselBlock } from "../blocks/PhoneCarousel"
import { PlainSelectorBlock } from "../blocks/PlainSelector"
import { SolidBoiBlock } from "../blocks/SolidBoi"
import { BasicBlock } from "../blocks/Basic"
import CtaFooter from "../components/cta-footer/footer"
import SEO from "../components/seo"

interface PageProps {
  title: string
  description: string
  bottomCta?: boolean
  useFooterDl?: boolean
  useCustomHeader?: boolean
}

export const Page: React.FC<PageProps> = ({
  title,
  description,
  bottomCta,
  children,
  useFooterDl = false,
  useCustomHeader = false,
}) => {
  return (
    <Layout footerDl={useFooterDl}>
      <SEO title={title} description={description} />
      {!useCustomHeader && (
        <section className="uk-section uk-section-primary section-header">
          <div className="uk-container uk-text-center">
            <h1>{title}</h1>
          </div>
        </section>
      )}
      {children}
      {bottomCta && <CtaFooter />}
    </Layout>
  )
}

export const PageForm = {
  label: "Page",
  fields: [
    {
      label: "Title",
      name: "rawJson.title",
      component: "text",
    },
    {
      label: "Description",
      name: "rawJson.description",
      component: "textarea",
    },
    {
      label: "Sections",
      name: "rawJson.blocks",
      component: "blocks",
      templates: {
        PhoneCarouselBlock,
        PlainSelectorBlock,
        SolidBoiBlock,
        BasicBlock,
      },
    },
  ],
}

// export const pageQuery = graphql`
//   query($path: String!) {
//     page: dataJson(fileRelativePath: { eq: $path }) {
//       # TinaCMS fields
//       rawJson
//       fileRelativePath
//     }
//   }
// `;
