import React from "react"
import { graphql } from "gatsby"
import { useLocalJsonForm } from "gatsby-tinacms-json"

import { PageForm, Page } from "../templates/page"

const PlayStore = require("../images/download-maxrewards-play-store.svg")

const AndroidUpgradePage = ({ data }) => {
  const [page]: any = useLocalJsonForm(data.dataJson, PageForm)

  return (
    <Page
      title={page?.title || ""}
      description={page?.description || ""}
      useFooterDl
    >
      <section className="uk-section uk-section-muted uk-overflow-hidden">
        <div
          className="uk-container uk-container-small uk-margin-large-bottom margin-collapse"
          data-uk-scrollspy="target: .uk-card, .uk-grid-small > *; cls: uk-animation-slide-bottom-medium; delay: 100"
        >
          <div className="uk-card uk-card-default uk-card-body">
            <h2 className="uk-text-left" style={{ marginBottom: 20 }}>
              Get the app.
            </h2>
            <div className="uk-flex uk-flex-center uk-flex-center@s uk-flex-right@m uk-flex-row-reverse uk-flex-wrap">
              {/* <div className="android-upgrade"> */}
              <a
                className="android-upgrade"
                href="https://maxrewards.com/maxrewards_android_app_v2_6_0.apk"
              >
                Download Now
              </a>
              {/* </div> */}
              <div className="uk-flex uk-flex-center">
                <a href="https://play.google.com/store/apps/details?id=com.maxrewards&hl=en_US&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <PlayStore className="height-xsmall" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  )
}

export const query = graphql`
  query androidQuery {
    dataJson(fileRelativePath: { eq: "/src/data/android.json" }) {
      title
      description

      # TinaCMS fields
      rawJson
      fileRelativePath
    }
  }
`

export default AndroidUpgradePage
